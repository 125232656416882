body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 0;
  margin: 0;
  font-family: UOLText,Arial,sans-serif;
}

a {
  color: #1082BE;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
}

@font-face {
  font-family: 'UOLText';
  src: local('UOLText'), url("./assets/fonts/uol-text-regular.otf") format('opentype');
}