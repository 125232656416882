.toggle-slider {
  position: relative;
  width: 40px;
  height: 14px;

}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.4s;
  padding: 2px 0px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  top: -3px;
  bottom: -3px;
  background-color: #666666;
  border-radius: 50%;
  transition: 0.4s;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:checked + .slider {
  background-color: #66BBEE;
}

input[type='checkbox']:checked + .slider:before {
  transform: translateX(20px);
  background-color: #1082BE;
}